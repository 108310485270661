import React from "react";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import CardTemplete from "../components/CardTemplete";

const ExampleNameCard = () => {
  const profileData = {
    companyImg:
      "https://yoov-name-card-demo-files-dev.s3.ap-east-1.amazonaws.com/YOOV-Logo-C.png",
    profileImg:
      "https://yoov-name-card-demo-files-dev.s3.ap-east-1.amazonaws.com/CarlChan.png",
    name: "John Doe",
    position: "Software Engineer",
    companyName: "Example Inc.",
    email: "te2st1222222@example.com",
    phone: "+1234567890",
    primaryColor: "#336699",
    secondaryColor: "#FFFFFF",
    companyImgPreSignedUrl:
      "https://marketing.yoov.com/vCARD/assets/YOOV-Logo-C.png",
    profileImgPreSignedUrl:
      "https://ncfile.yoov.plus/dc/64069b83183a32198ec91c14/f631503b-43fd-46dc-830e-6c38f5c86400.png?imageMogr2/ignore-error/1/format/webp",
  };
  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <CardTemplete profileData={profileData} />
    </StyleSheetManager>
  );
};

export default ExampleNameCard;
