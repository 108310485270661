import "./App.css";
import { useEffect } from "react";
import UserProfile from "./pages/UserProfile";
import ExampleNameCard from "./pages/ExampleNameCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "mq58onpa5o");
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/:id" element={<UserProfile />} />
            <Route path="/" element={<ExampleNameCard />} />
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
