import React, { useState, useEffect } from "react";
import { StyleSheetManager } from "styled-components";
import UserInfo from "../api/UserInfo";
import isPropValid from "@emotion/is-prop-valid";
import CardTemplete from "../components/CardTemplete";

const UserProfile = () => {
  const [profileData, setProfileData] = useState({});
  const { getUserInfo } = UserInfo();
  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserInfo();
      setProfileData(data);
    };
    fetchData();
  }, [getUserInfo]);

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <CardTemplete profileData={profileData} />
    </StyleSheetManager>
  );
};

export default UserProfile;
