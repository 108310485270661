import React from "react";
import styled from "styled-components";
import { GlobalColors } from "../components/constant/Global";
import NameCard from "./NameCard";

const CardWrapper = styled.div`
  width: 100%;
  height: 100vh;
  color: ${GlobalColors.dark};
  background: linear-gradient(
    to right,
    ${(props) => props.primary_color || GlobalColors.primary} 0%,
    ${(props) => props.secondary_color || GlobalColors.secondary} 100%
  );
`;

// const Banner = styled.div`
//   background: ${GlobalColors.specialBackground};
//   color: ${GlobalColors.white};
//   text-align: center;
//   margin-top: 5rem;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
// `;

// const BannerDescription = styled.p`
//   font-size: 1rem;
//   font-weight: ${GlobalFontWeight.bold};
//   color: ${GlobalColors.dark};
//   margin: 5px 0 5px 0;
// `;

// const PoweredBy = styled.p`
//   font-size: 0.8rem;
//   color: ${GlobalColors.dark};
//   margin-top: 20px;
// `;

// const HyperLink = styled.a`
//   color: ${GlobalColors.primary};
//   text-decoration: none;
//   cursor: pointer;
// `;

const CardTemplete = (data) => {
  const { profileData } = data;
  return (
    <CardWrapper
      primary_color={
        profileData ? profileData.primaryColor : GlobalColors.primary
      }
      secondary_color={
        profileData ? profileData.secondaryColor : GlobalColors.secondary
      }
    >
      <NameCard profileData={profileData} />
      {/* <Banner>
        <BannerDescription>
          Want to make a lasting impression?
        </BannerDescription>
        <BannerDescription>
          {" "}
          <HyperLink
            href={process.env.REACT_APP_ADMIN_PANEL_API_URL}
            target="_blank"
          >
            {" "}
            Create your personalized digital card now
          </HyperLink>{" "}
          and stay a step ahead in your professional journey!
        </BannerDescription>
        <PoweredBy>
          Powered by <HyperLink>YOOV</HyperLink>{" "}
        </PoweredBy>
      </Banner> */}
    </CardWrapper>
  );
};

export default CardTemplete;
