import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2rem;
`;

const LinkWrapper = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <NotFoundPageWrapper>
      <div>404</div>
      <div>User Not Found</div>
      <LinkWrapper onClick={() => navigate("/")}>
        Go to Example Name Card
      </LinkWrapper>
    </NotFoundPageWrapper>
  );
};

export default NotFoundPage;
