import VCard from "vcf";

const generateVCard = (user) => {
  const vCard = new VCard();

  vCard.add("n", `${user.name}`);
  vCard.add("org", user.companyName);
  vCard.add("title", user.position);
  vCard.add("email", user.email);
  vCard.add("tel", user.phone);
  vCard.add("note", user.note);

  return vCard.toString();
};

const downloadVCard = (vCardString, fileName) => {
  const blob = new Blob([vCardString], { type: "text/vcard" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export { generateVCard, downloadVCard };
