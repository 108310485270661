import { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const UserInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiBaseURL =
    process.env.REACT_APP_API_URL === undefined
      ? "http://localhost:5000"
      : process.env.REACT_APP_API_URL;

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${apiBaseURL}api/v1/name-card/display/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      navigate("/404");
    }
  }, [apiBaseURL, id, navigate]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return {
    getUserInfo,
  };
};

export default UserInfo;
