export const GlobalColors = {
    primary: '#2f80ed',
    secondary: '#56ccf2',
    button: '#3287ee',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#212529',
    white: '#ffffff',
    shadwon: 'rgba(0, 0, 0, 0.2)',
    specialBackground: 'rgba(255, 255, 255, 0.8)',
    hover:"#555556",
}

export const GlobalFontWeight = {
    bold: 700,
}

export const GlobalFontSize = {
    h1: 16,
    h2: 20,
    h3: 16,
    h4: 14,
    h5: 12,
    h6: 10,
    p: 14,
    small: 12,
}