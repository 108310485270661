import React, { useState } from "react";
import styled from "styled-components";
import { GlobalColors, GlobalFontWeight } from "./constant/Global";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FlipIcon from "@mui/icons-material/Flip";
import { downloadVCard, generateVCard } from "./CreateVCard";
import QRCode from "react-qr-code";

const NameCardWrapper = styled.div`
  box-shadow: 0 8px 16px 0 ${GlobalColors.shadwon};
  max-width: 260px;
  margin: auto;
  margin-top: 5%;
  text-align: center;
  background: ${GlobalColors.specialBackground};
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const CompanyImg = styled.img`
  width: ${(props) => props.logoConfig?.width || "50%"};
  height: ${(props) => props.logoConfig?.height || "auto"};
  border-radius: ${(props) => props.logoConfig?.borderRadius || "0"};
  margin: auto;
  margin-bottom: 16px;
`;

const ProfileImage = styled.img`
  border-radius: ${(props) => props.profileConfig?.borderRadius || "50%"};
  width: ${(props) => props.profileConfig?.width || "160px"};
  height: ${(props) => props.profileConfig?.height || "160px"};
  margin: 0 auto;
`;

const ProfileTitle = styled.h1`
  font-size: ${(props) => props.nameConfig?.fontSize || "1.6rem"};
  font-weight: ${(props) =>
    props.nameConfig?.fontWeight || GlobalFontWeight.bold};
  color: ${(props) => props.nameConfig?.fontColor || GlobalColors.dark};
  margin: 10px 0 10px 0;
`;

const ProfileDescription = styled.h2`
  font-size: ${(props) => props.contentConfig?.fontSize || "1rem"};
  font-weight: ${(props) =>
    props.contentConfig?.fontWeight || GlobalFontWeight.regular};
  color: ${(props) => props.contentConfig?.fontColor || GlobalColors.dark};
  line-height: 1.5;
  margin: 5px 0 5px 0;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const ProfileContact = styled.a`
  font-weight: 400;
  line-height: 1.5;
  margin: 5px 0 5px 0;
  text-decoration: none;
  color: ${GlobalColors.dark};
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    color: ${GlobalColors.primary};
    text-decoration: underline;
  }
`;

const ProfileWrapper = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileButtonTitle = styled.div`
  margin: 0 5px 0 5px;
`;

const ProfileButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.buttonConfig?.color || GlobalColors.primary};
  color: ${(props) => props.buttonConfig?.fontColor || GlobalColors.white};
  font-size: ${(props) => props.buttonConfig?.fontSize || "1rem"};
  font-weight: ${(props) =>
    props.buttonConfig?.fontWeight || GlobalFontWeight.bold};
  padding: 12px;
  margin: 5px 0 5px 0;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  text-decoration: none;
  &:hover {
    background: ${(props) =>
      props.buttonConfig?.hoverColor || GlobalColors.hover};
  }
`;

const FlipCard = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70vh;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: ${({ isFlipped }) =>
    isFlipped ? "rotateY(180deg)" : "rotateY(0)"};
`;

const FlipCardFront = styled.div`
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100;
`;

const FlipCardBack = styled.div`
  backface-visibility: hidden;
  transform: rotateY(180deg);
  // position: absolute;
  width: 100%;
  height: 100%;
`;

const QRCodeWrapper = styled.div`
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;
const CopyNotification = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${GlobalColors.specialBackground};
  color: ${GlobalColors.primary};
  font-size: 1rem;
  font-weight: ${GlobalFontWeight.bold};
  padding: 5px 10px;
  border-radius: 5px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
`;
const BackFlipContent = styled.div`
  font-size: 1rem;
  color: ${GlobalColors.dark};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const ContentLabel = styled.span`
  font-weight: ${GlobalFontWeight.bold};
  white-space: nowrap;
  margin-right: 0.5rem;
`;

const ContentValue = styled.span`
  font-weight: normal;
  flex: 1 1 calc(100% - 150px);
  min-width: 0;
  word-break: break-word;
  color: ${(props) => (props.isLink ? "blue" : "inherit")};
  text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
  cursor: ${(props) => (props.isLink ? "pointer" : "default")};
`;

function isHyperlink(text) {
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
  return urlPattern.test(text);
}

const NameCard = (data) => {
  const { profileData } = data;

  const { logoConfig, profileConfig, nameConfig, contentConfig, buttonConfig } =
    profileData;

  const [isFlipped, setIsFlipped] = useState(false);
  const qrCodeValue =
    (process.env.REACT_APP_NAME_CARD_API_URL || "http://localhost:3001/") +
    profileData.id;
  const [showNotification, setShowNotification] = useState(false);

  const profileItems = [
    { text: profileData.position },
    { text: profileData.companyName },
    {
      icon: <PhoneIcon />,
      text: profileData.phone,
      href: `tel:${profileData.phone}`,
    },
    { icon: <EmailIcon />, text: profileData.email },
  ];

  const extraInfo = profileData.extraInfo;

  const extraInfoItems =
    extraInfo &&
    Object.entries(extraInfo).map(([key, value]) => ({
      title: value.fieldName,
      text: value.fieldValue,
    }));

  const initialBackFlipItems = [
    profileData.companyName
      ? { title: "Company", text: profileData.companyName }
      : null,
    profileData.companyPhone
      ? { title: "Company Phone", text: profileData.companyPhone }
      : null,
    profileData.companyWebsite
      ? { title: "Company Website", text: profileData.companyWebsite }
      : null,
    profileData.companyAddress
      ? { title: "Company Address", text: profileData.companyAddress }
      : null,
  ].filter((item) => item !== null);

  let backFlipItems = initialBackFlipItems;
  if (extraInfoItems) {
    backFlipItems = [...initialBackFlipItems, ...extraInfoItems];
  }

  const handleDownloadVCard = () => {
    const vCardString = generateVCard(profileData);
    downloadVCard(
      vCardString,
      `${profileData.companyName}_${profileData.name}.vcf`
    );
  };

  const handleScanMe = () => {
    setIsFlipped(!isFlipped); // Flip the card to show the QR code
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(qrCodeValue)
      .then(() => {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 2000); // Notification disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsApp = () => {
    window.open(
      `https://wa.me/${profileData.phone.replace(/\D/g, "")}`,
      "_blank"
    );
  };

  const buttonsData = [
    {
      icon: <WhatsAppIcon />,
      title: "Message Me",
      handleOnClick: handleWhatsApp,
    },
    {
      icon: <PersonAddAltIcon />,
      title: "Add To Phone Book",
      handleOnClick: handleDownloadVCard,
    },
    {
      icon: <FlipIcon />,
      title: "Click To Know More",
      handleOnClick: handleScanMe,
    },
  ];

  return (
    <FlipCard isFlipped={isFlipped}>
      <FlipCardFront>
        <NameCardWrapper>
          {profileData.companyImgPreSignedUrl && (
            <CompanyImg
              src={profileData.companyImgPreSignedUrl}
              logoConfig={logoConfig}
            ></CompanyImg>
          )}
          {profileData.profileImgPreSignedUrl && (
            <ProfileImage
              src={profileData.profileImgPreSignedUrl}
              profileConfig={profileConfig}
            ></ProfileImage>
          )}
          <ProfileTitle nameConfig={nameConfig}>
            {profileData.name}
          </ProfileTitle>
          {profileItems.map((item, index) => (
            <ProfileWrapper key={index}>
              {item.href ? (
                <ProfileContact href={item.href}>
                  <ProfileDescription contentConfig={contentConfig}>
                    {item.icon}
                    {item.text}
                  </ProfileDescription>
                </ProfileContact>
              ) : (
                <ProfileDescription contentConfig={contentConfig}>
                  {item.icon && item.icon}
                  {item.text}
                </ProfileDescription>
              )}
            </ProfileWrapper>
          ))}
          {buttonsData.map((button, index) => (
            <ProfileButton
              key={index}
              buttonConfig={buttonConfig}
              onClick={button.handleOnClick}
            >
              {button.icon}
              <ProfileButtonTitle>{button.title}</ProfileButtonTitle>
            </ProfileButton>
          ))}
        </NameCardWrapper>
      </FlipCardFront>
      <FlipCardBack>
        <NameCardWrapper>
          <CompanyImg src={profileData.companyImgPreSignedUrl} />
          <QRCodeWrapper onClick={handleCopyToClipboard}>
            <QRCode
              title="GetMyNameCard"
              value={qrCodeValue}
              size={180}
              bgColor={GlobalColors.specialBackground}
            />
            <CopyNotification show={showNotification}>
              Copied to clipboard!
            </CopyNotification>
          </QRCodeWrapper>
          {backFlipItems.map((item, index) => {
            const isLink = isHyperlink(item.text);
            return (
              <BackFlipContent key={index}>
                <ContentLabel>{item.title}:</ContentLabel>
                <ContentValue
                  isLink={isLink}
                  onClick={() => isLink && window.open(item.text, "_blank")}
                >
                  {item.text}
                </ContentValue>
              </BackFlipContent>
            );
          })}
          <ProfileButton onClick={handleScanMe}>
            <ProfileWrapper>
              <FlipIcon />
              <ProfileButtonTitle>Back To Name Card</ProfileButtonTitle>
            </ProfileWrapper>
          </ProfileButton>
        </NameCardWrapper>
      </FlipCardBack>
    </FlipCard>
  );
};

export default NameCard;
